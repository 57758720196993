import _bitTwiddle from "bit-twiddle";
import _dup from "dup";
import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var bits = _bitTwiddle;
var dup = _dup;
var Buffer = _buffer.Buffer; //Legacy pool support

if (!_global.__TYPEDARRAY_POOL) {
  _global.__TYPEDARRAY_POOL = {
    UINT8: dup([32, 0]),
    UINT16: dup([32, 0]),
    UINT32: dup([32, 0]),
    BIGUINT64: dup([32, 0]),
    INT8: dup([32, 0]),
    INT16: dup([32, 0]),
    INT32: dup([32, 0]),
    BIGINT64: dup([32, 0]),
    FLOAT: dup([32, 0]),
    DOUBLE: dup([32, 0]),
    DATA: dup([32, 0]),
    UINT8C: dup([32, 0]),
    BUFFER: dup([32, 0])
  };
}

var hasUint8C = typeof Uint8ClampedArray !== "undefined";
var hasBigUint64 = typeof BigUint64Array !== "undefined";
var hasBigInt64 = typeof BigInt64Array !== "undefined";
var POOL = _global.__TYPEDARRAY_POOL; //Upgrade pool

if (!POOL.UINT8C) {
  POOL.UINT8C = dup([32, 0]);
}

if (!POOL.BIGUINT64) {
  POOL.BIGUINT64 = dup([32, 0]);
}

if (!POOL.BIGINT64) {
  POOL.BIGINT64 = dup([32, 0]);
}

if (!POOL.BUFFER) {
  POOL.BUFFER = dup([32, 0]);
} //New technique: Only allocate from ArrayBufferView and Buffer


var DATA = POOL.DATA,
    BUFFER = POOL.BUFFER;

exports.free = function free(array) {
  if (Buffer.isBuffer(array)) {
    BUFFER[bits.log2(array.length)].push(array);
  } else {
    if (Object.prototype.toString.call(array) !== "[object ArrayBuffer]") {
      array = array.buffer;
    }

    if (!array) {
      return;
    }

    var n = array.length || array.byteLength;
    var log_n = bits.log2(n) | 0;
    DATA[log_n].push(array);
  }
};

function freeArrayBuffer(buffer) {
  if (!buffer) {
    return;
  }

  var n = buffer.length || buffer.byteLength;
  var log_n = bits.log2(n);
  DATA[log_n].push(buffer);
}

function freeTypedArray(array) {
  freeArrayBuffer(array.buffer);
}

exports.freeUint8 = exports.freeUint16 = exports.freeUint32 = exports.freeBigUint64 = exports.freeInt8 = exports.freeInt16 = exports.freeInt32 = exports.freeBigInt64 = exports.freeFloat32 = exports.freeFloat = exports.freeFloat64 = exports.freeDouble = exports.freeUint8Clamped = exports.freeDataView = freeTypedArray;
exports.freeArrayBuffer = freeArrayBuffer;

exports.freeBuffer = function freeBuffer(array) {
  BUFFER[bits.log2(array.length)].push(array);
};

exports.malloc = function malloc(n, dtype) {
  if (dtype === undefined || dtype === "arraybuffer") {
    return mallocArrayBuffer(n);
  } else {
    switch (dtype) {
      case "uint8":
        return mallocUint8(n);

      case "uint16":
        return mallocUint16(n);

      case "uint32":
        return mallocUint32(n);

      case "int8":
        return mallocInt8(n);

      case "int16":
        return mallocInt16(n);

      case "int32":
        return mallocInt32(n);

      case "float":
      case "float32":
        return mallocFloat(n);

      case "double":
      case "float64":
        return mallocDouble(n);

      case "uint8_clamped":
        return mallocUint8Clamped(n);

      case "bigint64":
        return mallocBigInt64(n);

      case "biguint64":
        return mallocBigUint64(n);

      case "buffer":
        return mallocBuffer(n);

      case "data":
      case "dataview":
        return mallocDataView(n);

      default:
        return null;
    }
  }

  return null;
};

function mallocArrayBuffer(n) {
  var n = bits.nextPow2(n);
  var log_n = bits.log2(n);
  var d = DATA[log_n];

  if (d.length > 0) {
    return d.pop();
  }

  return new ArrayBuffer(n);
}

exports.mallocArrayBuffer = mallocArrayBuffer;

function mallocUint8(n) {
  return new Uint8Array(mallocArrayBuffer(n), 0, n);
}

exports.mallocUint8 = mallocUint8;

function mallocUint16(n) {
  return new Uint16Array(mallocArrayBuffer(2 * n), 0, n);
}

exports.mallocUint16 = mallocUint16;

function mallocUint32(n) {
  return new Uint32Array(mallocArrayBuffer(4 * n), 0, n);
}

exports.mallocUint32 = mallocUint32;

function mallocInt8(n) {
  return new Int8Array(mallocArrayBuffer(n), 0, n);
}

exports.mallocInt8 = mallocInt8;

function mallocInt16(n) {
  return new Int16Array(mallocArrayBuffer(2 * n), 0, n);
}

exports.mallocInt16 = mallocInt16;

function mallocInt32(n) {
  return new Int32Array(mallocArrayBuffer(4 * n), 0, n);
}

exports.mallocInt32 = mallocInt32;

function mallocFloat(n) {
  return new Float32Array(mallocArrayBuffer(4 * n), 0, n);
}

exports.mallocFloat32 = exports.mallocFloat = mallocFloat;

function mallocDouble(n) {
  return new Float64Array(mallocArrayBuffer(8 * n), 0, n);
}

exports.mallocFloat64 = exports.mallocDouble = mallocDouble;

function mallocUint8Clamped(n) {
  if (hasUint8C) {
    return new Uint8ClampedArray(mallocArrayBuffer(n), 0, n);
  } else {
    return mallocUint8(n);
  }
}

exports.mallocUint8Clamped = mallocUint8Clamped;

function mallocBigUint64(n) {
  if (hasBigUint64) {
    return new BigUint64Array(mallocArrayBuffer(8 * n), 0, n);
  } else {
    return null;
  }
}

exports.mallocBigUint64 = mallocBigUint64;

function mallocBigInt64(n) {
  if (hasBigInt64) {
    return new BigInt64Array(mallocArrayBuffer(8 * n), 0, n);
  } else {
    return null;
  }
}

exports.mallocBigInt64 = mallocBigInt64;

function mallocDataView(n) {
  return new DataView(mallocArrayBuffer(n), 0, n);
}

exports.mallocDataView = mallocDataView;

function mallocBuffer(n) {
  n = bits.nextPow2(n);
  var log_n = bits.log2(n);
  var cache = BUFFER[log_n];

  if (cache.length > 0) {
    return cache.pop();
  }

  return new Buffer(n);
}

exports.mallocBuffer = mallocBuffer;

exports.clearCache = function clearCache() {
  for (var i = 0; i < 32; ++i) {
    POOL.UINT8[i].length = 0;
    POOL.UINT16[i].length = 0;
    POOL.UINT32[i].length = 0;
    POOL.INT8[i].length = 0;
    POOL.INT16[i].length = 0;
    POOL.INT32[i].length = 0;
    POOL.FLOAT[i].length = 0;
    POOL.DOUBLE[i].length = 0;
    POOL.BIGUINT64[i].length = 0;
    POOL.BIGINT64[i].length = 0;
    POOL.UINT8C[i].length = 0;
    DATA[i].length = 0;
    BUFFER[i].length = 0;
  }
};

export default exports;
export const free = exports.free,
      freeUint8 = exports.freeUint8,
      freeUint16 = exports.freeUint16,
      freeUint32 = exports.freeUint32,
      freeBigUint64 = exports.freeBigUint64,
      freeInt8 = exports.freeInt8,
      freeInt16 = exports.freeInt16,
      freeInt32 = exports.freeInt32,
      freeBigInt64 = exports.freeBigInt64,
      freeFloat32 = exports.freeFloat32,
      freeFloat = exports.freeFloat,
      freeFloat64 = exports.freeFloat64,
      freeDouble = exports.freeDouble,
      freeUint8Clamped = exports.freeUint8Clamped,
      freeDataView = exports.freeDataView,
      freeBuffer = exports.freeBuffer,
      malloc = exports.malloc,
      mallocFloat32 = exports.mallocFloat32,
      mallocFloat64 = exports.mallocFloat64,
      clearCache = exports.clearCache;
const _freeArrayBuffer = exports.freeArrayBuffer,
      _mallocArrayBuffer = exports.mallocArrayBuffer,
      _mallocUint = exports.mallocUint8,
      _mallocUint2 = exports.mallocUint16,
      _mallocUint3 = exports.mallocUint32,
      _mallocInt = exports.mallocInt8,
      _mallocInt2 = exports.mallocInt16,
      _mallocInt3 = exports.mallocInt32,
      _mallocFloat = exports.mallocFloat,
      _mallocDouble = exports.mallocDouble,
      _mallocUint8Clamped = exports.mallocUint8Clamped,
      _mallocBigUint = exports.mallocBigUint64,
      _mallocBigInt = exports.mallocBigInt64,
      _mallocDataView = exports.mallocDataView,
      _mallocBuffer = exports.mallocBuffer;
export { _freeArrayBuffer as freeArrayBuffer, _mallocArrayBuffer as mallocArrayBuffer, _mallocUint as mallocUint8, _mallocUint2 as mallocUint16, _mallocUint3 as mallocUint32, _mallocInt as mallocInt8, _mallocInt2 as mallocInt16, _mallocInt3 as mallocInt32, _mallocFloat as mallocFloat, _mallocDouble as mallocDouble, _mallocUint8Clamped as mallocUint8Clamped, _mallocBigUint as mallocBigUint64, _mallocBigInt as mallocBigInt64, _mallocDataView as mallocDataView, _mallocBuffer as mallocBuffer };